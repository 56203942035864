<template>
  <div style="padding: 20px;">
     <el-button size="small" @click="addNotify('')" type="primary">添加</el-button>
      <!-- <SearchCommon :formInline="formInline" :formList="formList" @onSearch="onSearch" @onResetBtn="onResetBtn"></SearchCommon> -->
      <template>
          <TableCommon class="art" isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
            <el-table-column
              width="80"
              align="center"
              label="是否上线">
              <template slot-scope="scope">
               <span>{{ scope.row.status== 1 ? '上线':'下线' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="180"
              prop="createTime"
              align="center"
              label="创建时间">
            </el-table-column>
            <el-table-column
              width="120"
              align="center"
              label="操作">
              <template slot-scope="scope">
                <el-button @click="addNotify(scope.row)" type="text" size="small">编辑</el-button>
                <el-button type="text" @click="delNotify(scope.row)" size="small">删除</el-button>
              </template>
            </el-table-column>
          </TableCommon>
      </template>
      <el-pagination
      class="pagebox"
      @current-change="handleCurrentChange"
      :current-page="formInline.pageNum"
      :page-size="formInline.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
      <addNotify ref="addNotify" @restList="onLoadList" />
      <!-- <Handle ref="handle" @reloadList="onLoadList"/> -->
  </div>
</template>
<script>
import SearchCommon from '../../../components/SearchCommon.vue';
import TableCommon from '../feedback/TableCommon.vue';
import addNotify from './addNotify.vue'
export default {
  data() {
      return {
          vkey: 'pageNotify',
          formInline: {
              pageNum: 1,
              pageSize: 10
          },
          formList: [
              { label: '反馈类型', model: 'type', placeholder: '请输入工具名称', type: 'select',options: [
                  { value: null, label: '请选择'},{value:0,label:'功能需求'},{value:1,label:'系统问题'},{value:99,label:'商务合作'}] },
              { label: '是否受理', model: 'status', placeholder: '请选择', type: 'select', options: [
                  { value: null, label: '请选择'}, { value: 0, label: '未受理'}, { value: 1, label: '已受理'}, { value: 2, label: '拒绝受理'}]
              }
          ],
          tableColumn: [
              { label: '序号', type: 'index', align: 'center', width: '60' },
              { label: '公告标题', prop: 'titile', align: 'center'},
              { label: '公告内容', prop: 'content', align: 'center' },
          ]
      }
  },
  components: {
      TableCommon,
      SearchCommon,
      addNotify
      // Detail,Handle
  },
  computed: {
      tableData(){
          return this.$store.state.manage.views[this.vkey].list || []
      },
      total() {
          return this.$store.state.manage.views[this.vkey].paging.total;
      }
  },
  created(){
      this.onLoadList()
  },
  methods: {
    addNotify(item){
      this.$refs['addNotify'].show(item)
    },
    delNotify(item){
        this.$confirm('您确定要删除当前选中的公告吗, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(()=>{
            this.$store.dispatch('manage/public', {
            query:item.id,
            requestInterfaceName: 'delNotify'
            }).then(res=>{
                console.log(res)
                if(res.code==0){
                    this.$message.success('删除成功')
                    this.onLoadList()
                } else {
                    this.$message.error(res.message)
                }
            })
            
        }).catch(()=>{})
    },
    // 列表
    onLoadList(){
        this.$store.dispatch('manage/list', {
            vkey: this.vkey,
            reset: true,
            query: this.formInline,
            interfaceName: 'pageNotify'
        })
    },
    // 搜索
    onSearch() {
        this.formInline.pageNum = 1;
        this.onLoadList();
    },
    // 清空
    onResetBtn() {
        this.formInline = {
            name: '',
            pageNum: 1,
            pageSize: 18
        }
        this.onLoadList()
    },
    // 分页
    handleCurrentChange(val){
        this.formInline.pageNum = val;
        this.onLoadList();
    }
  }
}
</script>
<style lang="less" scoped>
.art {
    margin-top: 20px;
 /deep/ .el-table {
      .el-table__body-wrapper {
          .el-table__body tr td {
              padding: 10px !important;
          }
      }
  } 
}
</style>