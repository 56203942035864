<template>
  <el-dialog
      :title="ruleForm.id?'编辑系统公告':'添加系统公告'"
      :visible.sync="showFeedBack"
      width="40%"
      @close="resetForm('ruleForm')"
      :close-on-click-modal="false"
      custom-class="feenback"
      :append-to-body="true">
      <div class="feenback-tools-content">
        <el-form :model="ruleForm" :rules="rules"  ref="ruleForm" label-width="auto" class="demo-ruleForm">
          <el-form-item label="公告标题" prop="titile">
            <el-input size='small' placeholder='请填写公告标题' v-model="ruleForm.titile"></el-input>
            </el-form-item>
            <el-form-item label="公告内容"  prop="content">
              <el-input type="textarea" :rows="4" size='small' placeholder='请填写公告内容'  v-model="ruleForm.content" ></el-input>
            </el-form-item>
            <el-form-item label="是否上线" prop="status">
              <el-radio-group v-model="ruleForm.status">
                <el-radio  :label="1">是</el-radio>
                <el-radio  :label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="btn">
              <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
              <el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
            </el-form-item>
          </el-form>
      </div>
  </el-dialog>
</template>
<script>
import { manageAPI } from 'liankong-ui-api'
export default {
  data() {
      return {
        showFeedBack:false,
        ruleForm: {
          status:1,
          titile:'',
          content:'',
          type:1,
          isTop:0
        },
        rules:{
          titile:[ { required: true, message: '请输入公告标题', trigger: 'blur' }],
          content:[{ required: true, message: '请填写公告内容', trigger: 'blur' }],
          status: [{ required: true, message: '请选择是否上限', trigger: 'change' }],
        }
      }
  },
  methods: {
    show(item){
      if(item==''){
        this.ruleForm = {status:1,titile:'',content:'',type:1,isTop:0}
      } else {
        const {status,titile,content,id} = item
        this.ruleForm = {status,titile,content,id,type:1,isTop:0}
      }
      this.showFeedBack = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch('manage/public', {
            query:this.ruleForm,
            requestInterfaceName: this.ruleForm.id ? 'editNotify':'addNotify'
            }).then(res=>{
                console.log(res)
                if(res.code==0){
                    this.$message.success(this.ruleForm.id?'编辑成功':'添加成功')
                    this.$emit('restList')
                    this.resetForm(formName)
                } else {
                    this.$message.error(res.message)
                }
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm={status:1,titile:'',content:'',type:1,isTop:0}
      this.showFeedBack = false
    }
  }
}
</script>
<style lang="less">

</style>
<style lang="less" scoped>
/deep/ .feenback{
.el-dialog__body {
  padding:10px 70px 20px 70px;
}
.feenback-tools-content {
  font-size: 14px;
    p {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        span {
            padding-top: 10px;
            box-sizing: border-box;
        }
    }
    .el-form-item__content {
      // margin-left:0px !important;
      .el-textarea,.el-select,.el-input{
        width: 85%;
        .el-input {
          width: 100%;
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-form-item__content {
        margin-left:0px !important;
      }
    }
    .upload-list {
      ul,li{
        list-style: none;
      }
      .el-form-item__content {
        line-height: 30px;
      }
      .el-upload__tip {
        margin-top:0;
      }
      li{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        box-sizing: border-box;
        .cure {
          cursor: pointer;
        }
      }
    }
}
}

</style>